<template>
  <div>
    <IsHeader :activeIndex="'3'"></IsHeader>

    <div
      style="
        background-color: #f8f8f8;
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: -7px;
      "
    ></div>
    <div class="aboutUs" style="margin-top: 20px">
      <!--报价描述 -->
      <div class="containerWidth">
        <div class="Content">
          <h2>C系统 价格优惠清单</h2>
          新的数据引擎，可以对多订单系统，多平台的账单进行实时核对，
          满足财务的实时性、准确性的要求。核销结果提供了丰富的导出接口，
          实现与财务系统的无缝对接。
        </div>
      </div>
      <!-- 报价表格 -->
      <el-table :data="priceList" border style="width: 900px">
        <el-table-column
          prop="functionModule"
          label="功能"
          :width="computeHeight == 'pc' ? '300px' : '125px'"
          align="center"
        />
        <el-table-column
          label="专业版"
          :width="computeHeight == 'pc' ? '300px' : '125px'"
          align="center"
        >
          <template v-slot="{ row }">
            <div>{{ row.professionPrice }}</div>
          </template>
        </el-table-column>

        <el-table-column
          label="高性能版"
          :width="computeHeight == 'pc' ? '300px' : '125px'"
          align="center"
        >
          <template v-slot="{ row }">
            <div>{{ row.highPrice }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 获取报价按钮 -->
      <div>
        <el-row>
          <el-col :span="24" style="margin-top: 15px;margin-left:15px">
            <el-button
              style="
                border: none;
                color: #fff;
                padding: 17px;
                border-radius: 100px;
                background-color: #f2001d;
                font-family: Novecentowide-DemiBold;
                white-space: pre-line;
              "
              class="getPriceButton"
              @click="getProfessionPrice"
            >
              获取最新方案折扣
            </el-button>
          </el-col>
          <!-- <el-col :span="8" style="text-align: center; margin-top: 12px">
            <el-button
              style="
                font-size: 17px;
                border: none;
                color: #fff;
                padding: 12px;
                border-radius: 100px;
                background-color: #f2001d;
                font-family: Novecentowide-DemiBold;
                white-space: pre-line;
              "
              @click="gethighPrice"
            >
              获取方案折扣
            </el-button>
          </el-col> -->
        </el-row>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          flex-direction: column;
          transition: 0.5s all;
        "
        v-if="flag1 || flag2"
      >
        <!-- 获取最新报价表单 -->
        <h2>获取最新方案折扣</h2>
        <div>
          您可以现在就联系我们，告诉我们您的具体需求，我们会给您最合适的方案和报价。
        </div>
        <div>
          <el-form
            class="formBox"
            :model="form"
            ref="ruleFormRef"
            :rules="rules"
          >
            <el-form-item label="您的邮箱" prop="mailbox">
              <el-input
                placeholder="请输入您的邮箱"
                v-model="form.mailbox"
              ></el-input>
            </el-form-item>
            <el-form-item label="您的姓名" prop="memberName">
              <el-input
                placeholder="请输入您的姓名 "
                v-model="form.memberName"
              ></el-input>
            </el-form-item>
            <el-form-item label="您的电话" prop="mobile">
              <el-input
                placeholder="请输入您的电话"
                v-model="form.mobile"
              ></el-input>
            </el-form-item>
            <el-form-item label="您的职位" prop="position">
              <el-input
                placeholder="请输入您的职位"
                v-model="form.position"
              ></el-input>
            </el-form-item>
            <el-form-item label="您的部门" prop="department">
              <el-input
                placeholder="请输入您的部门"
                v-model="form.department"
              ></el-input>
            </el-form-item>
            <el-form-item label="您的公司" prop="company">
              <el-input
                placeholder="请输入您的公司"
                v-model="form.company"
              ></el-input>
            </el-form-item>
          </el-form>
          <i
            >我们会遵守公司的相关协议和保密政策，不会向第三方透漏您的任何信息。</i
          >
          <div>
            <el-button
              style="
                font-size: 17px;
                border: none;
                color: #fff;
                padding: 20px 25px;
                border-radius: 100px;
                background-color: #f2001d;
                font-family: Novecentowide-DemiBold;
                white-space: pre-line;
                margin: 20px;
              "
              @click="PostMessage"
            >
              发送
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <IsFooter></IsFooter>
  </div>
</template>

<script>
export default {
  name: "Quotation",
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth;

      if (screenHeight > 915) {
        return "pc";
      } else {
        return "mobile";
      }
    },
  },
};
</script>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { wxfeaturePrice, wxSoftwareConsult } from "@/api/index";
import IsHeader from "./Public/IsHeader.vue";
import IsFooter from "./Public/IsFooter.vue";
onMounted(() => {
  getwxfeaturePrice();
});
const priceList = ref([{}, {}, {}, {}, {}]);
const flag1 = ref(false);
const flag2 = ref(false);
const getwxfeaturePrice = async () => {
  try {
    const result = await wxfeaturePrice();
    result.data = result.data.map((item) => {
      return {
        ...item,
        professionPrice: "￥" + item.professionPrice,
        highPrice: "￥" + item.highPrice,
      };
    });

    priceList.value = result.data;
  } catch (error) {}
};
const getProfessionPrice = async () => {
  flag1.value = true;
  // try {
  //   if (flag1.value) {
  //     return;
  //   }
  //   const result = await wxfeaturePrice();
  //   result.data = result.data.map((item) => {
  //     if (flag2.value) {
  //       return {
  //         ...item,
  //         professionPrice: "￥" + item.professionPrice,
  //         highPrice: "￥" + item.highPrice,
  //       };
  //     } else {
  //       return {
  //         ...item,
  //         professionPrice: "￥" + item.professionPrice,
  //         highPrice: "--",
  //       };
  //     }
  //   });

  //   priceList.value = result.data;
  // } catch (error) {}
};
const gethighPrice = async () => {
  flag2.value = true;
  // try {
  //   if (flag2.value) {
  //     return;
  //   }
  //   const result = await wxfeaturePrice();
  //   result.data = result.data.map((item) => {
  //     if (flag1.value) {
  //       return {
  //         ...item,
  //         professionPrice: "￥" + item.professionPrice,
  //         highPrice: "￥" + item.highPrice,
  //       };
  //     } else {
  //       return {
  //         ...item,
  //         professionPrice: "--",
  //         highPrice: "￥" + item.highPrice,
  //       };
  //     }
  //   });

  //   priceList.value = result.data;
  // } catch (error) {}
};

const form = ref({
  mailbox: "",
  memberName: "",
  mobile: "",
  position: "",
  department: "",
  company: "",
});

const validateMailbox = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入您的邮箱"));
  } else {
    if (!/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)) {
      callback(new Error("请输入正确的邮箱格式"));
    } else {
      callback();
    }
  }
};
const validateName = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入您的姓名"));
  } else {
    if (!/^[\u4e00-\u9fa5a-zA-Z]+$/.test(value)) {
      callback(new Error("请输入正确的姓名格式"));
    } else {
      callback();
    }
  }
};
const validateMobile = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入您的电话"));
  } else {
    if (
      !/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
        value
      )
    ) {
      callback(new Error("请输入正确的电话"));
    } else {
      callback();
    }
  }
};
const validatePosition = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入您的职位"));
  } else {
    if (!/[\u4e00-\u9fa5]/gm.test(value)) {
      callback(new Error("请输入正确的职位"));
    } else {
      callback();
    }
  }
};
const validateDepartment = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入您的部门"));
  } else {
    if (!/[\u4e00-\u9fa5]/gm.test(value)) {
      callback(new Error("请输入正确的部门"));
    } else {
      callback();
    }
  }
};
const validateCompany = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入您的公司"));
  } else {
    if (!/[\u4e00-\u9fa5]/gm.test(value)) {
      callback(new Error("请输入正确的公司"));
    } else {
      callback();
    }
  }
};
const rules = reactive({
  mailbox: [
    {
      validator: validateMailbox,
      required: true,
      trigger: "blur",
    },
  ],
  memberName: [
    {
      validator: validateName,
      required: true,
      trigger: "blur",
    },
  ],
  mobile: [
    {
      validator: validateMobile,
      required: true,
      trigger: "blur",
    },
  ],
  position: [
    {
      validator: validatePosition,
      required: true,
      trigger: "blur",
    },
  ],
  department: [
    {
      validator: validateDepartment,
      required: true,
      trigger: "blur",
    },
  ],
  company: [
    {
      validator: validateCompany,
      required: true,
      trigger: "blur",
    },
  ],
});

const tableData = [
  {
    date: "订单中心",
    name: "￥100",
    address: "￥99999",
  },
  {
    date: "账单中心",
    name: "￥100",
    address: "￥99999",
  },
  {
    date: "核销中心",
    name: "￥100",
    address: "￥99999",
  },
  {
    date: "对接中心",
    name: "￥100",
    address: "￥99999",
  },
  {
    date: "配置中心",
    name: "￥100",
    address: "￥99999",
  },
  {
    date: "实施服务",
    name: "￥100",
    address: "￥99999",
  },
];
const ruleFormRef = ref(null);
// 立即预约要进行表单验证

const PostMessage = async () => {
  await ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        await wxSoftwareConsult();
        ElMessage.success("预约成功");
        form.value = {
          mailbox: "",
          memberName: "",
          mobile: "",
          position: "",
          department: "",
          company: "",
        };
      } catch (error) {
        ElMessage.error("预约失败请微信联系");
      }
    }
  });
};
</script>

<style scoped>
.aboutUs {
  width: 900px;
  margin: 0 auto;
  min-height: 620px;
}
.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.Content {
  line-height: 30px;
  margin-bottom: 10px;
}
.containerWidth {
  width: 60%;
}
.formBox {
  width: 60%;
  margin: 30px 0px 0px 30px;
}
.getPriceButton {
  font-size: 17px;
}
@media screen and (max-device-width: 915px) {
  .aboutUs {
    width: 100%;
  }
  .containerWidth {
    width: 100%;
  }
  .formBox {
    width: 80%;
    margin: 30px 0px 0px 30px;
  }
  .getPriceButton {
    font-size: 10px;
  }
}
</style>

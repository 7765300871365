<template>
  <div>
    <IsHeader :activeIndex="'1'"></IsHeader>
    <div
      style="
        background-color: #f2001d;
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: -7px;
      "
    ></div>
    <div class="pcCss">
      <div onselectstart="return false;">
        <div
          style="
            background-color: #f2001d;
            width: 100%;
            height: 700px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          "
          class="MobileImg"
        >
          <!-- 图片 -->
          <div>
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
              style="width: 502px; height: 400px"
            />
          </div>
          <div class="oneImg" style="width: 600px; margin-bottom: 90px">
            <div style="font-size: 17px; font-weight: bold">C核销</div>
            <div style="font-size: 34px; font-weight: bold; margin-top: 45px">
              专为企业定制专属财务合规方案系统
            </div>
            <!-- <div style="font-size: 34px; font-weight: bold">
              我们将会为您定制专属业财合规方案。
            </div> -->
            <div
              style="
                font-size: 20px;
                font-weight: bold;
                margin-top: 60px;
                line-height: 30px;
              "
            >
              C核销系统设计了高性能、稳定的数据引擎和平台，
              丰富的业务处理模式和流程，多业务系统和平台的对接方案，
              为您构建可配置、自开发、稳健高效业财合规体系。
            </div>
          </div>
        </div>
      </div>
      <div style="width: 1200px; margin: 0 auto">
        <!--   <div style="margin-top: 40px" class="tab-container">
          <!~~ 手写tabs ~~>
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ tab: true, active: activeTab === index }"
            @click="changeTab(index)"
          >
            {{ tab }}
          </div>
        </div> -->

        <div style="margin-top: 40px" class="tab-container">
          <!-- 手写tabs -->

          <el-tabs type="border-card">
            <el-tab-pane label="核销结果">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: start;
                  margin-top: 50px;
                "
              >
                <div>
                  <img
                    src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
                    style="width: 502px; height: 400px"
                  />
                </div>
                <!-- 右侧解释文字 -->
                <div
                  :style="{
                    'margin-left': '88px',
                    'line-height': '30px',
                    width: '500px',
                  }"
                >
                  <h2>清晰、条理、一目了然的结果</h2>

                  <div style="margin-left: 22px">
                    <div class="yuan">
                      核销结果按照店铺，结账月份等维度进行区分，同事让你很容易区分已核销和未核销到的账单，订单。
                    </div>
                    <div style="margin-top: 20px" class="yuan">
                      核销系统会自动对核销结果进行归类汇总，核销结果直观。
                    </div>
                    <div style="margin-top: 20px" class="yuan">
                      核销结果实时呈现
                    </div>
                  </div>
                  <div style="margin-top: 20px">
                    新的数据引擎，可以对多订单系统，多平台的账单进行实时核对，
                    满足财务的实时性、准确性的要求。核销结果提供了丰富的导出接口，
                    实现与财务系统的无缝对接。
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="订单中心">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: start;
                  margin-top: 50px;
                "
              >
                <div>
                  <img
                    src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
                    style="width: 502px; height: 400px"
                  />
                </div>
                <!-- 右侧解释文字 -->
                <div
                  :style="{
                    'margin-left': '88px',
                    'line-height': '30px',
                    width: '500px',
                  }"
                >
                  <h2>多系统的对接平台，各个渠道的订单集中管理</h2>

                  <div style="margin-left: 22px">
                    <div class="yuan">
                      可对接OMS、线下系统、自研商城等多个平台。
                    </div>
                    <div class="yuan" style="margin-top: 20px">
                      可以对订单进行不同分类。
                    </div>
                    <div class="yuan" style="margin-top: 20px">
                      可以对不同平台的订单进行分类处理。
                    </div>
                  </div>
                  <div style="margin-top: 20px">
                    订单的集中管理，满足了既满足了财务对不同渠道的订单分类管理，
                    也可以实现订单进入财务系统之前进行数据转换和汇总。
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="账单中心">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: start;
                  margin-top: 50px;
                "
              >
                <div>
                  <img
                    src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
                    style="width: 502px; height: 400px"
                  />
                </div>
                <!-- 右侧解释文字 -->
                <div
                  :style="{
                    'margin-left': '88px',
                    'line-height': '30px',
                    width: '500px',
                  }"
                >
                  <h2>清晰、条理、一目了然的结果</h2>

                  <div style="margin-left: 22px">
                    <div class="yuan">
                      核销结果按照店铺，结账月份等维度进行区分，同事让你很容易区分已核销和未核销到的账单，订单。
                    </div>
                    <div class="yuan" style="margin-top: 20px">
                      核销系统会自动对核销结果进行归类汇总，核销结果直观。
                    </div>
                    <div class="yuan" style="margin-top: 20px">
                      核销结果实时呈现
                    </div>
                  </div>
                  <div style="margin-top: 20px">
                    新的数据引擎，可以对多订单系统，多平台的账单进行实时核对，
                    满足财务的实时性、准确性的要求。核销结果提供了丰富的导出接口，
                    实现与财务系统的无缝对接。
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <!--什么是C系统  -->
        <div class="whatCtitle">
          <h2>Why C-system?</h2>
          <div style="color: #aaaaaa; font-size: 20px; font-weight: normal">
            您需要的就是我们的C系统
          </div>
        </div>

        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: start;
            margin-top: 89px;
          "
        >
          <!-- 左侧解释文字 -->
          <div
            :style="{
              'line-height': '30px',
              width: '500px',
            }"
          >
            <div style="margin-top: 13px">
              <div>
                对于规模化的企业，我们相信您已经有了非常完备、齐全的各个业务系统。
                但由于缺少中间系统的连接，导致了各个的连接变得非常困难。
              </div>
              <div style="margin-top: 53px">
                尤其财务核销需要的业务数据和账单数据的核对工作难度更大。
              </div>
              <div style="margin-top: 53px">
                因此，在您具有非常的完备的财务系统（A系统）和业务系统（B系统）的情况下，
                您需要的就是我们的C系统。
              </div>
            </div>
          </div>
          <div style="margin-left: 88px">
            <div>
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C1.png"
                alt=""
                style="width: 100px; height: 60px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C2.png"
                alt=""
                style="width: 100px; height: 60px; margin-left: 10px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C3.png"
                alt=""
                style="width: 100px; height: 60px; margin-left: 10px"
              />
            </div>
            <div>
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/1690428782217.jpg"
                alt=""
                style="width: 150px; margin: 10px 0 10px 80px"
                class="tiaodong"
              />
            </div>
            <div>
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C4.png"
                alt=""
                style="width: 100px; height: 60px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C5.png"
                alt=""
                style="width: 100px; height: 60px; margin-left: 10px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C6.png"
                alt=""
                style="width: 100px; height: 60px; margin-left: 10px"
              />
            </div>
          </div>
        </div>

        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: start;
            margin-top: 270px;
          "
        >
          <div>
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
              style="width: 502px; height: 400px"
            />
          </div>
          <!-- 右侧解释文字 -->
          <div
            :style="{
              'line-height': '30px',
              width: '500px',
              'margin-left': '88px',
            }"
          >
            <h2>系统内灵活的配置，满足了各行业、企业的业务复杂性和多变性</h2>
            <div style="margin-top: 43px">
              <div class="yuan">
                随着企业的快速发展，业务经营模式也会面临频繁的调整，C系统内的配置，可以满足日常业务的需要。
              </div>
              <div class="yuan" style="margin-top: 43px">
                同时，本地化的部署，系统维护人员能够查询数据，开发人员能够进行二次开发。
              </div>
            </div>
          </div>
        </div>
        <!-- 助力各行业品牌logo -->
        <div class="title">
          <h2>助力各行业，各品牌发展</h2>
        </div>
        <!-- 各品牌logo -->
        <div style="display: flex; justify-content: space-between">
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand1.jpg"
              alt=""
              class="brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand2.jpg"
              alt=""
              class="brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand3.png"
              alt=""
              class="brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand04.jpg"
              alt=""
              class="brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand5.png"
              alt=""
              class="brandImg"
            />
          </div>
        </div>
        <!-- C系统的优势 -->
        <div class="title">
          <h2>C系统的优势</h2>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 100px;
          "
        >
          <div style="margin-left: 56px; line-height: 36px">
            <h2>定制化的财务合规方案</h2>
            <div class="yuan">多平台的对接方案</div>
            <div class="yuan">配置式灵活的核销方案</div>
            <div class="yuan">可二次开发的开发平台</div>
            <div class="yuan">核销结果的分段式确认</div>
            <div class="yuan">专业的开发团队和实施团队</div>
          </div>
          <!-- 这里放客服图片 -->
          <div>
            <div
              class="mr40"
              style="overflow: hidden; display: inline-block; margin-top: 10px"
            >
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Customer.jpg"
                alt=""
                class="kefuImg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="MobileCss">
      <div onselectstart="return false;">
        <div
          style="
            background-color: #f2001d;
            width: 100%;
            height: 269px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          "
        >
          <!-- 图片 -->
          <div>
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
              style="width: 180px; height: 150px"
            />
          </div>
          <div class="Mobile_oneImg" style="width: 600px">
            <div style="font-size: 6px; font-weight: bold">C核销</div>
            <div style="font-size: 8px; font-weight: bold; margin-top: 9px">
              专为企业定制专属财务合规方案系统
            </div>
            <!-- <div style="font-size: 8px; font-weight: bold; margin-top: 9px">
              我们将会为您定制专属业财合规方案。
            </div> -->
            <div style="font-size: 10px; font-weight: bold; margin-top: 6px">
              C核销系统设计了高性能、稳定的数据引擎和平台，
              丰富的业务处理模式和流程，多业务系统和平台的对接方案，
              为您构建可配置、自开发、稳健高效业财合规体系。
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; margin: 0 auto">
        <div style="margin-top: 40px" class="tab-container">
          <!-- 手写tabs -->
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ tab: true, active: activeTab === index }"
            @click="changeTab(index)"
          >
            {{ tab }}
          </div>
        </div>
        <!-- 对应tabs的内容 -->
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
          "
          v-if="activeTab == 0"
        >
          <div>
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
              style="width: 180px; height: 150px"
            />
          </div>
          <!-- 右侧解释文字 -->
          <div
            :style="{
              'margin-left': '8px',
              'line-height': '20px',
              'font-size': '8px',
            }"
          >
            <h2 style="font-size: 8px">清晰、条理、一目了然的结果</h2>

            <div style="margin-left: 2px">
              <div class="yuan">
                核销结果按照店铺，结账月份等维度进行区分，同事让你很容易区分已核销和未核销到的账单，订单。
              </div>
              <div class="yuan" style="margin-top: 20px">
                核销系统会自动对核销结果进行归类汇总，核销结果直观。
              </div>
              <div class="yuan" style="margin-top: 20px">-核销结果实时呈现</div>
            </div>
            <div style="margin-top: 20px">
              新的数据引擎，可以对多订单系统，多平台的账单进行实时核对，
              满足财务的实时性、准确性的要求。核销结果提供了丰富的导出接口，
              实现与财务系统的无缝对接。
            </div>
          </div>
        </div>

        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
          "
          v-if="activeTab == 1"
        >
          <div>
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
              style="width: 180px; height: 150px"
            />
          </div>
          <!-- 右侧解释文字 -->
          <div
            :style="{
              'margin-left': '8px',
              'line-height': '20px',
              'font-size': '8px',
            }"
          >
            <h2 style="font-size: 8px">
              多系统的对接平台，各个渠道的订单集中管理
            </h2>

            <div style="margin-left: 2px">
              <div class="yuan">可对接OMS、线下系统、自研商城等多个平台。</div>
              <div class="yuan" style="margin-top: 20px">
                可以对订单进行不同分类。
              </div>
              <div class="yuan" style="margin-top: 20px">
                可以对不同平台的订单进行分类处理。
              </div>
            </div>
            <div style="margin-top: 20px">
              订单的集中管理，满足了既满足了财务对不同渠道的订单分类管理，
              也可以实现订单进入财务系统之前进行数据转换和汇总。
            </div>
          </div>
        </div>
        <!--什么是C系统  -->
        <div class="whatCtitle">
          <h2>Why C-system?</h2>
          <div style="color: #aaaaaa; font-size: 20px; font-weight: normal">
            您需要的就是我们的C系统
          </div>
        </div>

        <div style="width: 100%; height: 100%; text-align: center">
          <div style="margin: 0 auto">
            <div>
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C1.png"
                alt=""
                style="width: 60px; height: 30px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C2.png"
                alt=""
                style="width: 60px; height: 30px; margin-left: 10px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C3.png"
                alt=""
                style="width: 60px; height: 30px; margin-left: 10px"
              />
            </div>
            <div>
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/1690428782217.jpg"
                alt=""
                style="width: 100px; margin: 10px 0 10px 20px"
                class="tiaodong"
              />
            </div>
            <div>
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C4.png"
                alt=""
                style="width: 60px; height: 30px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C5.png"
                alt=""
                style="width: 60px; height: 30px; margin-left: 10px"
              />
              <img
                src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Cimgs/C6.png"
                alt=""
                style="width: 60px; height: 30px; margin-left: 10px"
              />
            </div>
          </div>
          <!-- 下边解释文字 -->
          <div
            :style="{
              'line-height': '30px',
              'text-align': 'start',
            }"
          >
            <div style="margin-top: 13px">
              <div>
                对于规模化的企业，我们相信您已经有了非常完备、齐全的各个业务系统。
                但由于缺少中间系统的连接，导致了各个的连接变得非常困难。
              </div>
              <div style="margin-top: 13px">
                尤其财务核销需要的业务数据和账单数据的核对工作难度更大。
              </div>
              <div style="margin-top: 13px">
                因此，在您具有非常的完备的财务系统（A系统）和业务系统（B系统）的情况下，
                您需要的就是我们的C系统。
              </div>
            </div>
          </div>
        </div>

        <!--下面内容  -->
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 19px;
          "
        >
          <!-- 左侧解释文字 -->
          <div :style="{ width: '500px', 'font-size': '8px' }">
            <h2>系统内灵活的配置，满足了各行业、企业的业务复杂性和多变性</h2>
            <div style="margin-top: 8px">
              <div class="yuan">
                随着企业的快速发展，业务经营模式也会面临频繁的调整，XX系统内的配置，可以满足日常业务的需要。
              </div>
              <div class="yuan" style="margin-top: 8px">
                同时，
                本地化的部署，系统维护人员能够查询数据，开发人员能够进行二次开发。
              </div>
            </div>
          </div>
          <div style="margin-left: 8px">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/indexTop.png"
              style="width: 180px; height: 150px"
            />
          </div>
        </div>
        <!-- 助力各行业品牌logo -->
        <div class="Mobile_title">
          <h2>助力各行业，各品牌发展</h2>
        </div>
        <!-- 各品牌logo -->
        <div style="display: flex; justify-content: space-between">
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand1.jpg"
              alt=""
              class="Mobile_brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand2.jpg"
              alt=""
              class="Mobile_brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand3.jpg"
              alt=""
              class="Mobile_brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand04.jpg"
              alt=""
              class="Mobile_brandImg"
            />
          </div>
          <div style="overflow: hidden">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/Logo/brand5.jpg"
              alt=""
              class="Mobile_brandImg"
            />
          </div>
        </div>
        <!-- c系统的优势 -->
        <div class="Mobile_title">
          <h2>C系统 的优势</h2>
        </div>
        <div style="margin-left: 56px; font-size: 8px; margin-bottom: 30px">
          <h2>定制化的财务合规方案</h2>
          <div class="yuan">多平台的对接方案</div>
          <div class="yuan">配置式灵活的核销方案</div>
          <div class="yuan">可二次开发的开发平台</div>
          <div class="yuan">核销结果的分段式确认</div>
          <div class="yuan">专业的开发团队和实施团队</div>
        </div>
      </div>
    </div>
    <IsFooter></IsFooter>
  </div>
</template>

<script>
export default {
  name: "HellowWord",
  props: {
    msg: String,
  },
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        return "550px";
      } else {
        return "250px";
      }
    },
  },
};
</script>

<script setup>
// import Swiper from "./swiper-bundle.min.js";
// import "./swiper-bundle.min.css";
import IsHeader from "./Public/IsHeader.vue";
import IsFooter from "./Public/IsFooter.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
const activeTab = ref(0); // 当前选中的 Tab
const tabs = ref(["核销结果", "订单中心", "账单中心"]); // 当前选中的 Tab

onMounted(() => {});
const changeTab = (index) => {
  activeTab.value = index; // 切换 Tab
};
</script>
<style>
.pcCss {
  display: block;
}
.MobileCss {
  display: none;
}
@media screen and (max-device-width: 915px) {
  .pcCss {
    display: none;
  }
  .MobileCss {
    display: block;
  }
}
</style>
<style scoped>
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  font-size: 26px;
  padding: 40px;
}
::v-deep .el-tabs__item {
  width: 400px;
  font-size: 26px;
  color: #000;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #f2001d;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #f2001d;
}
/* 跳动 */
.tiaodong {
  animation: jump 1.5s infinite;
}
/* 加小圆圈 */
.yuan:before {
  content: "●";
  margin-right: 10px;
  color: #666;
}
@keyframes jump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.kefuImg {
  width: 460px;
  /* height: 240px; */
  overflow: hidden;
}
.kefuImg:hover {
  transform: scale(1.2, 1.2);
  transition: 0.5s;
}
.mr40 {
  margin-right: 40px;
}
.oneImg {
  margin-left: 106px;
  color: #fff;
  font-family: Novecentowide-DemiBold;
}
.Mobile_oneImg {
  margin-left: 6px;
  color: #fff;
  font-family: Novecentowide-DemiBold;
}
.tab-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border-bottom: 2px solid #000; */
  /* width: 1200px; */
  margin: 0 auto;
  transition: all 0.5s;
}

.tab {
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 26px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  /* border-bottom: 1px solid #000; */
  transition: all 0.5s;
}

.tab.active {
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  background-color: #333;
  color: #fff;
  transition: all 0.5s;
}

.whatCtitle {
  padding: 40px 0 10px;
  background: #f8f8f8;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  font-family: "Verdana";
  font-weight: 700;
  font-style: normal;
}
.whatCtitle:hover h2:after {
  position: absolute;
  left: 50%;
  bottom: 45px;
  display: block;
  content: "";
  width: 100px;
  height: 3px;
  margin-left: -50px;
  background: #f2001d;
}
.title {
  font-family: "Verdana";
  padding: 40px 0 10px;
  background: #f8f8f8;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.title:hover h2:after {
  position: absolute;
  left: 50%;
  bottom: 20px;
  display: block;
  content: "";
  width: 100px;
  height: 3px;
  margin-left: -50px;
  background: #f2001d;
}
.Mobile_title {
  font-size: 10px;
  padding: 10px 0 10px;
  background: #f8f8f8;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
.Mobile_title :after {
  position: absolute;
  left: 50%;
  bottom: 15px;
  display: block;
  content: "";
  width: 60px;
  height: 3px;
  margin-left: -30px;
  background: #f2001d;
}
.brandImg {
  width: 220px;
  height: 110px;
}
.Mobile_brandImg {
  width: 70px;
  height: 35px;
}
.brandImg:hover {
  transform: scale(1.2, 1.2);
  transition: 0.5s;
}
/*中等设备 大于等于992小于等于1200px*/
@media (max-width: 992px) {
  /* .pcCss{
  background: red;
} */
}
</style>

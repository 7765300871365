<template>
  <div>
    <div class="header">
      <el-row>
        <el-col :span="12">
          <div class="logo_img" @click="logoClick">
            <div>
              <div class="header-logo">C</div>
            </div>
            <div style="height: 100px; line-height: 100px">
              <div class="company_name">核销-财务对账系统</div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div id="menu_index">
            <!-- web端菜单导航  3AAEFD -->
            <div style="position: relative">
              <el-menu
                v-if="seen"
                :default-active="activeIndex"
                class="el-menu-demo"
                menu-trigger="click"
                text-color="#333"
                style="
                  height: 100px;
                  border: 0;
                  background-color: rgba(0, 0, 0, -0.5);
                "
                mode="horizontal"
                @select="handleSelect"
                active-text-color="#007BFF"
              >
                <el-menu-item index="1" class="header-title">
                  <span> 商业版本</span>
                </el-menu-item>
                <!-- <el-menu-item index="2" class="header-title">功能</el-menu-item> -->
                <el-menu-item index="3" class="header-title">
                  报价
                </el-menu-item>
              </el-menu>
              <div
                style="
                  position: absolute;
                  right: 0px;
                  top: 50%;
                  margin-top: -20px;
                "
              >
                <el-button
                  style="
                    font-size: 17px;
                    border: none;
                    color: #fff;
                    padding: 20px 20px;
                    border-radius: 100px;
                    background-color: #f2001d;
                    font-family: Novecentowide-DemiBold;
                    white-space: pre-line;
                  "
                >
                  体验版
                </el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="header2">
      <el-row>
        <el-col :span="12">
          <div id="menu_index">
            <!-- web端菜单导航  3AAEFD -->
            <div style="position: relative">
              <el-menu
                v-if="seen"
                :default-active="activeIndex"
                class="el-menu-demo"
                menu-trigger="click"
                text-color="#333"
                style="
                  height: 100px;
                  border: 0;
                  background-color: rgba(0, 0, 0, -0.5);
                "
                mode="horizontal"
                @select="handleSelect"
                active-text-color="#007BFF"
              >
                <el-menu-item index="1" class="header-title">
                  <span> 商业版本</span>
                </el-menu-item>
                <!-- <el-menu-item index="2" class="header-title">功能</el-menu-item> -->
                <el-menu-item index="3" class="header-title">
                  报价
                </el-menu-item>
              </el-menu>
              <div
                style="
                  position: absolute;
                  right: 40px;
                  top: 50%;
                  margin-top: -20px;
                "
              >
                <el-button
                  style="
                    font-size: 17px;
                    border: none;
                    color: #fff;
                    padding: 20px 20px;
                    border-radius: 100px;
                    background-color: #f2001d;
                    font-family: Novecentowide-DemiBold;
                    white-space: pre-line;
                  "
                >
                  体验版
                </el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 固定定位 -->
    <div class="ToFoxed">
      <!-- 1 -->
      <div
        class="fixBox oneFixbox"
        style="position: relative"
        @mouseenter="onToFoxedOneHover"
        @mouseleave="handleToFoxedOneLeave"
      >
        <div>
          <i class="iconfont icon-weixin"></i>
        </div>
        <div style="margin-top: 5px">微信咨询</div>
        <div
          class="peopleCard"
          style="position: absolute; left: -140px; top: 0px; display: none"
        >
          <img
            src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/WeChat.jpg"
            style="width: 140px; height: 140px"
          />
        </div>
      </div>
      <!-- 2 -->
      <div
        class="twoFixbox"
        @mouseenter="onToFoxedTwoHover"
        @mouseleave="handleToFoxedTwoLeave"
      >
        <div style="text-align: center; width: 65px">
          <i class="iconfont icon-24gf-phoneBubble"> </i>
          <div style="margin-top: 5px">电话</div>
        </div>
        <div
          class="peopelTel"
          style="
            text-align: center;
            border-left: 1px solid #fff;
            line-height: 70px;
            width: 135px;
          "
        >
          15990103290
        </div>
      </div>
      <!-- 3 -->
      <div class="fixBox threeFixbox" @click="srcollToTop">
        <div>
          <i class="iconfont icon-zhiding" style="text-algin: center"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<script setup>
import { onMounted, ref, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
defineProps({
  activeIndex: {
    type: String,
    default: "1",
  },
});
// let activeIndex = "1";
let company = {
  qrcode:'https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/WeChat.jpg',
};
let scroll = "";
let timer = "";
let seen = true;
const currentMenu = ref("首页");

onMounted(() => {
  menuSeen();
});
const handleSelect = (key) => {
  switch (key) {
    case "1":
      router.push({ name: "Index" });
      break;

    case "3":
      console.log("跳转到关于车我们");
      router.push({ name: "Quotation" });
      break;
  }
};
const handleSelectMobile = (key) => {
  switch (key) {
    case "1":
      router.push({ name: "Index" });
      break;

    case "3":
      router.push({ name: "Quotation" });
      break;
  }
};
const handleScroll = () => {
  this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
  if (this.scroll > 600) {
    //判断滚动后高度超过400px,就显示
    this.$refs.btn.style.display = "block";
  } else {
    this.$refs.btn.style.display = "none";
  }
};
const backTop = () => {
  //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
  this.timer = setInterval(() => {
    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
    let ispeed = Math.floor(-osTop / 5);
    document.documentElement.scrollTop = document.body.scrollTop =
      osTop + ispeed;
    if (osTop === 0) {
      clearInterval(this.timer);
    }
  }, 30);
};
const login = () => {
  window.open("https://admin.fastice-tech.com/login");
};
const register = () => {
  window.open("https://admin.fastice-tech.com/registe");
};
const menuSeen = () => {
  // 屏幕尺寸
  let screenHeight = document.body.clientWidth;
  console.log(screenHeight, "screenHeight");
  if (screenHeight > 415) {
    seen = true;
  } else {
    seen = false;
  }
};
const logoClick = () => {
  currentMenu.value = "首页";
};

// 侧面栏
// 侧面栏-微信咨询
const onToFoxedOneHover = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "block";
  oneFixbox.style.background = "#494949";
};
// 侧面栏微信咨询离开
const handleToFoxedOneLeave = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "none";
  oneFixbox.style.background = "#23282e";
};
// 侧面栏。电话
const onToFoxedTwoHover = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "0";
};
const handleToFoxedTwoLeave = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "136px";
};

// 回到顶部
const srcollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 添加平滑滚动效果
  });
}; //回到游览器顶部
</script>

<style scoped>
::v-deep .el-menu-demo .el-menu-item {
  margin-right: 50px;
}
::v-deep .el-menu .el-menu-item {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
  z-index: 99;
}
::v-deep .el-menu .el-menu-item:hover {
  color: #fff !important;
  background: rgba(0, 0, 0, -0.5) !important;
  border-bottom: 2px solid #fff;
}
::v-deep .el-menu .el-menu-item.is-active {
  color: #fff !important;
  opacity: 1;
  background: rgba(0, 0, 0, -0.5) !important;
  border-bottom: 2px solid #fff;
}
::v-deep .el-menu .el-menu-item.is-active:hover {
  color: #fff !important;
}

.ToFoxed {
  position: fixed;
  right: 0px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 99;
}
/* 底部cart样式 */
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
}
.bottomCart2 {
  display: none;
}
.header2 {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 99;
}
@media screen and (max-device-width: 768px) {
  .ToFoxed {
    display: none;
  }
  .header {
    display: none;
  }
  .header2 {
    display: block;
  }
  .bottomCart {
    display: none;
  }
  .bottomCart2 {
    display: block;
  }
}
/* 侧边栏固定定位 */
.twoFixbox {
  position: relative;
  left: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
}
.fixBox {
  position: relative;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
}

.threeFixbox:hover {
  width: 77px; /* 变宽一倍 */
}

.fixBox:hover > div:last-child {
  margin-top: 0; /* 取消上边距 */
}

/* 底部cart样式
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
} */

.logo_img {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  margin-left: 178px;
}
#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}
.header-title {
  font-size: 1em;
}
.company_name {
  font-weight: bold;
  font-size: 1em;
  margin-left: 10px;
}
.footer_font {
  font-size: 1em;
}
#menu_index {
  width: 390px;
}
.header {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 99;
}
/* @media screen and (max-device-width: 415px) {
  .logo_img {
    float: left;
    width: auto;
    cursor: pointer;
  }
  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer;
  }
  .footer_font {
    font-size: 0.875em;
  }
  #menu_index {
    float: right;
    width: auto;
    height: 100%;
  }
  .mobile_menu {
  }
} */
#header-img {
  width: 50px;
  height: 50px;
  margin: auto;
}
.header-logo {
  height: 100%;
  width: 100%;
  align-self: center;
  font-family: "Segoe Script Bold", "Segoe Script Normal", "Segoe Script";
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  color: rgb(255, 255, 255);
  /* background-color: #d9001b; */
  text-align: center;
  line-height: 45px;
  /* padding: 0px; */
}

.footer {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 90%;
}
a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
@media (max-width: 992px) {
  .logo_img {
    margin-left: 15px;
    font-size: 14px;
  }
  #header-img {
    width: 20px;
    height: 20px;
    margin: auto;
  }
  .header-logo {
    height: 100%;
    width: 100%;
    align-self: center;
  }
}
</style>

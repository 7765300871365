
import axios from "axios";
import Nprogress from "nprogress"; //引入js
import "nprogress/nprogress.css"; //引入css


// 1、配置基础路径和超时限制
const instance = axios.create({
  baseURL: "https://crm.bodorme.com/coseast-applets-web", //公共的基础路径
  timeout: 20000,
});

// 2、添加进度条信息  nprogress（开启进度条）
instance.interceptors.request.use(
  // 请求拦截器的成功
  (config) => {
    Nprogress.start();
    config.headers.Accept = "application/json";
    return config;
  }
);

// 3、返回的响应不再需要从data属性当中拿数据，而是响应就是我们要的数据
instance.interceptors.response.use(
  (response) => {
    Nprogress.done();

    if (response.data.code == 10000) {
      return response.data;
    } else {
 
    }
  },
  (error) => {
    // 4、统一处理请求错误, 具体请求也可以选择处理或不处理
    Nprogress.done();
    alert("网络繁忙,请联系管理员");
    return Promise.reject(error); //让错误信息继续传递下去
  }
);

export default instance;


import request from './network.js';


// 咨询业务接口
export function wxSoftwareConsult (parms) {
    return request({
        url: '/wx/add/consult',
        method: 'POST',
        data: {
            ...parms
        }
    });
}
// 获取报价价格
export function wxfeaturePrice (parms) {
    return request({
        url: 'wx/feature/price',
        method: 'get',
        params: {
            ...parms
        }
    });
}
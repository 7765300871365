
import {createRouter,createWebHashHistory} from 'vue-router'
import Index from '../components/Index'
import aboutUs from '../components/Quotation'

   const routes= [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/Quotation',
            name: 'Quotation',
            component: aboutUs
        },
    ]
const router = createRouter({
    history:createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      // 始终滚动到顶部
      return { top: 0 }
    }
  })

  
  
  export default router
  
  
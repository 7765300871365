<template>
  <div class="container" style="clear: both">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style scoped>


.container {
  width: 100%;
  min-width: 1200px; 
  background-color: #f8f8f8;
}
@media screen and (max-device-width: 768px) {
  .container {
  width: 100%;
  min-width: 0px; 
  background-color: #f8f8f8;
}
}
</style>

<template>
  <div>
    <div style="width: 100%; margin: 20px auto" class="bottomCart">
      <el-row>
        <el-col :span="14">
          <div style="margin-left:178px;color: color: rgba(255,255,255,0.48);">
            <p class="footer_font">Tel:15990103290</p>
            <p class="footer_font">邮箱：lizhigang@coseast.com</p>
            <div style="margin: auto" class="footer_font">
              AD:浙江省杭州市拱墅区丰潭路380号城西银泰E座2幢8F
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div style="text-algin: center">
            <div>
              <img
                :src="company.qrcode"
                style="width: 100px; height: 100px; border: 0"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="width: 90%; margin: 20px auto">
        <div style="height: 1px; padding: 0; background-color: #999"></div>
      </div>
      <div style="margin: auto; margin-left: 178px" class="footer_font">
        © All Rights Reserved 浙江杭州妆点信息技术有限公司 版权所有
        浙ICP备15043724号-1
      </div>
      <div></div>
    </div>
    <!-- 2 -->
    <div style="width: 100%; background: #494949" class="bottomCart2">
      <div
        style="
          color: rgba(255, 255, 255, 0.48);
          text-align: center;
          padding: 20px 10px;
        "
      >
        <div
          style="
            margin: 0 auto;
            color: rgba(255, 255, 255, 0.48);
            font-size: 10px;
            line-height: 20px;
          "
        >
          ©杭州妆点信息技术有限公司 版权所有 浙ICP备15043724号-1
        </div>
        <div style="margin: 0 auto; font-size: 10px; line-height: 20px">
          AD:浙江省杭州市拱墅区丰潭路380号城西银泰E座2幢8F
        </div>
        <div style="font-size: 10px; line-height: 20px">
          邮箱：lizhigang@coseast.com
        </div>
        <div style="font-size: 10px; line-height: 20px">Tel:15990103290</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  
};
</script>

<script setup>
let company = {
  qrcode: 'https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/WriteOffWeb/WeChat.jpg',
};
</script>

<style scoped>
::v-deep .el-menu .el-menu-item {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
  z-index: 99;
}
::v-deep .el-menu .el-menu-item:hover {
  color: #fff !important;
  background: rgba(0, 0, 0, -0.5) !important;
  border-bottom: 2px solid #fff;
}
::v-deep .el-menu .el-menu-item.is-active {
  color: #fff !important;
  opacity: 1;
  background: rgba(0, 0, 0, -0.5) !important;
  border-bottom: 2px solid #fff;
}
::v-deep .el-menu .el-menu-item.is-active:hover {
  color: #fff !important;
}

.ToFoxed {
  position: fixed;
  right: 0px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 99;
}
/* 底部cart样式 */
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
}
.bottomCart2 {
  display: none;
}
.header2 {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 99;
}
@media screen and (max-device-width: 768px) {
  .ToFoxed {
    display: none;
  }
  .header {
    display: none;
  }
  .header2 {
    display: block;
  }
  .bottomCart {
    display: none;
  }
  .bottomCart2 {
    display: block;
  }
}
/* 侧边栏固定定位 */
.twoFixbox {
  position: relative;
  left: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
}
.fixBox {
  position: relative;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
}

.threeFixbox:hover {
  width: 77px; /* 变宽一倍 */
}

.fixBox:hover > div:last-child {
  margin-top: 0; /* 取消上边距 */
}

/* 底部cart样式
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
} */

.logo_img {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  margin-left: 178px;
}
#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}
.header-title {
  font-size: 1em;
}
.company_name {
  font-weight: bold;
  font-size: 1em;
  margin-left: 10px;
}
.footer_font {
  font-size: 1em;
}
#menu_index {
  width: 390px;
}
.header {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 99;
}
/* @media screen and (max-device-width: 415px) {
  .logo_img {
    float: left;
    width: auto;
    cursor: pointer;
  }
  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer;
  }
  .footer_font {
    font-size: 0.875em;
  }
  #menu_index {
    float: right;
    width: auto;
    height: 100%;
  }
  .mobile_menu {
  }
} */
#header-img {
  width: 50px;
  height: 50px;
  margin: auto;
}
.header-logo {
  height: 50px;
  width: 50px;
  align-self: center;
}

.footer {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 90%;
}
a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
</style>
